/* custom-carousel.css */

.slick-dots li button:before {
  margin-top: 10px;
  /* margin-bottom: 30px; */
  /* margin: 10px 20px 20px 20px */
    font-size: 24px; /* Increase the size of the dots */
    color: gainsboro;
    ; /* Change the color of the dots */
    opacity: 0.75; /* Adjust the opacity */
}

.slick-dots li.slick-active button:before {
    /* row-gap: 10px; */
    color: #6CC551; /* Change the color of the active dot */
    opacity: 1; /* Make the active dot fully opaque */
}

.slick-dots li button:hover:before {
    color: #6CC551;
     /* Change the color of the dots on hover */
}
@media (max-width:640px){
  .slick-dots li button:before {
    font-size:15px;
  }
}
  
