.image-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.image {
   
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.image.active {
    opacity: 1;
}

  
/* Example animation for sliding effect */
.image:nth-child(1) {
    transform: translateX(0%);
}

* {
    font-family: 'Roboto';
}



.image:nth-child(2) {
    transform: translateX(-100%);
}

.image:nth-child(3) {
    transform: translateX(-200%);
}

.image:nth-child(4) {
    transform: translateX(-300%);
}
.link-active {
    background-color: black;
    color: white;
  }
  
  /* For mobile navigation */
  .link-active {
    background-color: black;
    color: white;
  }
  