@tailwind base;
@tailwind components;
@tailwind utilities;




/* styles.css */

/* Style the Google Translate gadget container */
#\:0\.targetLanguage.goog-te-gadget-simple {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing as needed */
    /* Example background color */
    padding: 8px; /* Example padding */
    border-radius: 4px; /* Example border radius */
}

/* Style the Google Translate icon */
#\:0\.targetLanguage .goog-te-gadget-icon {
    width: 24px; /* Adjust icon size */
    height: 24px; /* Adjust icon size */
    background-image: url("https://translate.googleapis.com/translate_static/img/te_ctrl3.gif");
    background-position: -65px 0;
    background-repeat: no-repeat;
}

/* Style the Select Language link */
#\:0\.targetLanguage a {
    text-decoration: none;
    /* color: #333; Example text color */
    font-size: 14px; /* Example font size */
    display: flex;
    align-items: center;
}

/* Style the dropdown arrow */
#\:0\.targetLanguage span[aria-hidden="true"] {
    margin-left: 4px; /* Example margin for the arrow */
/* Example color for the arrow */
}


@media screen and (max-width: 850px) {
    #\:0\.targetLanguage .goog-te-gadget-icon {
        width: 20px;
        height: 20px;
    }
    #\:0\.targetLanguage a {
        font-size: 10px;
    }
}

@media screen and (max-width: 680px) {
    #\:0\.targetLanguage .goog-te-gadget-icon {
        width: 17px;
        height: 20px;
    }
    #\:0\.targetLanguage a {
        font-size: 10px;
    }
}
@media screen and (max-width: 405px) {
    #\:0\.targetLanguage .goog-te-gadget-icon {
        width: 20px;
        height: 20px;
    }
    #\:0\.targetLanguage a {
        font-size: 7px;
    }
}
    
